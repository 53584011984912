import React, {Component} from 'react';
import './App.css';
import BookView from './BookView'
import { PayPalButton } from "react-paypal-button-v2";
import { Document, Page } from 'react-pdf/dist/entry.webpack';
 
import NavBar from './NavBar';
import { Auth } from 'aws-amplify';
import { fontFamily } from '@material-ui/system';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import Grid from '@material-ui/core/Grid';

import Snackbar from '@material-ui/core/Snackbar';
import { borders } from '@material-ui/system'
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import pdf from "./TDLM.epub.cover.pdf"
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const promoCode = "EG1EECYPX"
const discountCode = "DISCOUNT20"

const SANDBOX_ID = "AQwO23Y5AtLj2Fk-cJuyVgp5KwqKNyT6-aesAoLrKxexRp6wKSqASIRbKNw75WZWMOR1MSOFc24raQR1";
const PROD_ID = "Ae2mJsND4GJsDODOdDjV35PMlsikO1wF9Yhgnw9mEdY9GX1WwugXoRN2uSpRxONjcyFofitqUkn5dt9C"

var environment = process.env.ENV
var region = process.env.REGION


class App extends Component {
    constructor(props) {
      super(props)
      this.state = {
        documentRender: false,
        accessCodeModalOpen: false,
        discountCodeModalOpen: false,
        modalOpen: false,
        confirmationModalOpen: false,
        finishedModalOpen: false,
        bundleBoughtModalOpen: false,
        username: "",
        password: "",
        email: "",
        name: "",
        code: "",
        accessCode: "",
        attemptSubmitAccessCode: false,
        ifBundleSelected: undefined,
        individualPrice: 68,

        address: "",
        city: "",
        state: "",
        zip: "",
        country: "",

        confirmationSnackBar: false,
        errorSnackBar: false,
        error: ""
      }
    }

    componentDidMount() {
      //this.setState({ bundleBoughtModalOpen: true})
      // this.actuallySendEmail()
      this.setState({documentRender: true})
      Auth.currentAuthenticatedUser()
      .then((user) => { console.log(user.username); this.setState({documentRender: true, username: user.username}) })
      .catch((user, err) => { 
        this.setState({documentRender: false}) 
      })
    }

    enterBookView() {
      this.setState({documentRender: true})
    }

    exitBookView() {
      this.setState({documentRender: false})
    }

    addEmail() {
      Auth.signUp({
        username: this.state.username,
        password: this.state.password,
        attributes: {
          email: this.state.email
      },
      })
        .then(data => {
          console.log(data)
          this.setState({
            modalOpen: false
          }, () => this.setState({confirmationModalOpen: true}))
        })
        .catch(err => this.setState({errorSnackBar: true, error: err}));
    }

    resendCode() {
      Auth.resendSignUp(this.state.username).then(() => {
          console.log('code resent successfully');
          this.setState({
            confirmationSnackBar: true
          });
      }).catch(err => this.setState({errorSnackBar: true, error: err}));
    }

    confirmCode() {
      // After retrieving the confirmation code from the user
      Auth.confirmSignUp(this.state.username, this.state.code, {
        // Optional. Force user confirmation irrespective of existing alias. By default set to True.
        forceAliasCreation: true    
      }).then(data => {
        console.log(data)
        this.setState({
          confirmationModalOpen: false,
          finishedModalOpen: true
        })
      })
      .catch(err => this.setState({errorSnackBar: true, error: err}));
    }

    submitAccessCode() {
      if (this.state.accessCode === promoCode) {
        this.setState({
          accessCodeModalOpen: false,
          accessCode: ""
        }, () => 
          this.setState({
            modalOpen: true
          })
        )
      }
      else if (this.state.accessCode === discountCode) {
        this.setState({
          accessCodeModalOpen: false
        }, () => 
          this.setState({
            discountCodeModalOpen: true,
            accessCode: "",
            individualPrice: 60
          })
        )
      }
      else {
        this.setState({
          attemptSubmitAccessCode: true
        })
      }
    }

    openAccessCodeModal() {
      this.setState({accessCodeModalOpen: true})
    }

    actuallySendEmail() {
      const url = "https://5e1jvioihe.execute-api.us-east-1.amazonaws.com/prod/mail?"
      + "name=" + this.state.name +
      "&address=" + this.state.address + 
      "&city=" + this.state.city + 
      "&zip=" + this.state.zip + 
      "&country=" + this.state.country +
      "&email=" + this.state.email;

      console.log(url)

      fetch(url)
        .then(response => console.log(response))
    }

    sendEmail() {
      const { address, city, state, zip, country} = this.state
      if (address === "") {
        this.setState({errorSnackBar: true, error: {message: "Address cannot be left blank"}})
      }
      else if (city === "") {
        this.setState({errorSnackBar: true, error: {message: "City cannot be left blank"}})
      }
      else if (state === "") {
        this.setState({errorSnackBar: true, error: {message: "State cannot be left blank"}})
      }
      else if (zip === "") {
        this.setState({errorSnackBar: true, error: {message: "Zip code cannot be left blank"}})
      }
      else if (country === "") {
        this.setState({errorSnackBar: true, error: {message: "Country cannot be left blank"}})
      }
      else {
        this.actuallySendEmail()
        this.setState({
          bundleBoughtModalOpen: false
        }, () => this.setState({modalOpen: true}))
      }
   }
    
    render() {
      return(
        this.state.documentRender ? <BookView exitBookView={() => this.exitBookView()} />
        : 
        <div style={{textAlign: "center"}}>
          <NavBar username={this.state.username} onClick={() => this.enterBookView()} openAccessCodeModal={() => this.openAccessCodeModal()} loggedIn={false}/>
          <Container spacing={3}>
          <Paper style={{paddingTop: "20px", paddingBottom: "30px",borderRight: "5px"}}>
              <Typography variant="h5" style={{fontFamily: 'Lato'}}>
                Welcome to The Directed Listening Model Ebook Reader!
              </Typography>
              If you have already purchased the e-book, LOG IN above to view your book (internet required). <br></br>
               If you have not purchased the book, select a purchase option and payment method below. <br></br>
               Upon payment, you will be directed to create a user account and LOG IN credentials.
            </Paper>

              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <Paper style={{display: "grid", justifyItems: "center", justifyContent: "center", boxShadow: "-1px 0px 0px 0px #DCDCDC"}}>
                  <Document
                        file={pdf}
                        onLoadSuccess={this.onDocumentLoadSuccess}
                        onLoadError={console.error}>

                        <Page pageNumber={1} height={300}/>
                        
                    </Document>
                    <Typography style={{fontFamily: 'Lato'}}>
                    <b>E-book: ${this.state.individualPrice}.00</b>
                    </Typography>
                    <Radio
                      checked={this.state.ifBundleSelected !== undefined && !this.state.ifBundleSelected}
                      onChange={() => this.setState({ifBundleSelected: false})}
                      value={false}
                      name="bundle-select"
                      style= {{color: "black", backgroundColor: "white"}}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper style={{display: "grid", justifyItems: "center",justifyContent: "center",boxShadow: "1px 0px 0px 0px #DCDCDC"}}>
                  <Document
                        file={pdf}
                        onLoadSuccess={this.onDocumentLoadSuccess}
                        onLoadError={console.error}>
                          
                        <Page pageNumber={1} height={300}/>
                        
                    </Document>
                    <Typography style={{fontFamily: 'Lato', textAlign: "center"}}>
                    <b>Print + E-book: $106.00 ($50 off)</b>
                    </Typography>
                    <Radio
                      checked={this.state.ifBundleSelected == true}
                      onChange={() => this.setState({ifBundleSelected: true})}
                      value={true}
                      name="bundle-select"
                      style= {{color: "black", backgroundColor: "white"}}
                    />
                  </Paper>
                </Grid>
              </Grid>

              {
                this.state.ifBundleSelected !== undefined ?
                  <Paper style={{paddingTop: "30px"}}>
                  <PayPalButton
                    amount={this.state.ifBundleSelected ? 106 : this.state.individualPrice}
                    // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                    options={{"NOSHIPPING": 1}}
                    onSuccess={(details, data) => {
                      console.log(details.payer.name.given_name + " " + details.payer.name.surname)
                      this.setState({
                        email: details.payer.email_address,
                        name: details.payer.name.given_name + " " + details.payer.name.surname,
                      }, () => {
                        if (this.state.ifBundleSelected) {
                          this.setState({bundleBoughtModalOpen: true})
                        }
                        else {
                          this.setState({modalOpen: true})
                        }
                      })
                      console.log(details)
                      console.log(data)
                    }}
                    options={{
                      clientId: PROD_ID
                    }}
                  />
                </Paper>
              : null
              }
          </Container>

          <Dialog open={this.state.accessCodeModalOpen} onClose={() => this.setState({accessCodeModalOpen: false})}>
            <DialogTitle>Please enter an access or discount code</DialogTitle>
            <DialogContent>
              <div>
                <TextField
                  error={this.state.accessCode !== promoCode && this.state.attemptSubmitAccessCode}
                  helperText={this.state.accessCode !== promoCode && this.state.attemptSubmitAccessCode ? 'Incorrect Promo Code' : ' '}
                  label="Code" value={this.state.accessCode} onChange={(e) => this.setState({accessCode: e.target.value})}/>
              </div>
            </DialogContent>
            <DialogActions>
              <Button color="inherit" onClick={() => this.submitAccessCode()}>
                Submit
              </Button>
            </DialogActions>
          </Dialog>
          
          <Dialog open={this.state.bundleBoughtModalOpen}>
            <DialogTitle>Thanks for your purchase!</DialogTitle>
            <DialogContent>
              <DialogContentText>The print version of the Directed Listening Model will be shipped to the address you provided. To access the DLM e-book, please create a Username and Password below.</DialogContentText>
              <div>
                <TextField label="Street Address" onChange={(e) => this.setState({address: e.target.value})} />
              </div>
              <div>
                <TextField label="City" onChange={(e) => this.setState({city: e.target.value})} />
              </div>
              <div>
                <TextField label="State/Providence" onChange={(e) => this.setState({state: e.target.value})} />
              </div>
              <div>
                <TextField label="Zip Code" onChange={(e) => this.setState({zip: e.target.value})} />
              </div>
              <div>
                <TextField label="Country" onChange={(e) => this.setState({country: e.target.value})} />
              </div>
            </DialogContent>
            <DialogActions>
              <Button color="inherit" onClick={() => this.sendEmail()}>
                Submit
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={this.state.discountCodeModalOpen}>
            <DialogTitle>Discount code successfully applied!</DialogTitle>
            <DialogContent>
                The E-book option now costs $60.00. <b>Please keep this website open until after you purchase the e-book.</b>
            </DialogContent>
            <DialogActions>
              <Button color="inherit" onClick={() => this.setState({discountCodeModalOpen: false})}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
          
          <Dialog open={this.state.modalOpen}>
            <DialogTitle>Thank you for your purchase of the e-book!</DialogTitle>
            <DialogContent>
              <DialogContentText>Please create a Username and Password to access the DLM e-book.</DialogContentText>
              <DialogContentText>Password must be at least 8 characters long.</DialogContentText>
              <div>
                <TextField label="Username" onChange={(e) => this.setState({username: e.target.value})} />
              </div>
              <div>
                <TextField label="Password" type="password" onChange={(e) => this.setState({password: e.target.value})}/>
              </div>
              <div>
                <TextField label="Email Address" value={this.state.email} onChange={(e) => this.setState({email: e.target.value})}/>
              </div>
            </DialogContent>
            <DialogActions>
              <Button color="inherit" onClick={() => this.addEmail()}>
                Submit
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={this.state.confirmationModalOpen}>
            <DialogTitle>We've emailed you a confirmation code to {this.state.email}, please enter it below.</DialogTitle>
            <DialogContent>
              <TextField label="Confirmation code" onChange={(e) => this.setState({code: e.target.value})} />
            </DialogContent>

            <DialogActions>
              <Button color="inherit" onClick={() => this.resendCode()}>
                Resend Code
              </Button>
              <Button color="inherit" onClick={() => this.confirmCode()}>
                Submit
              </Button>
            </DialogActions>

          </Dialog>

          <Dialog open={this.state.finishedModalOpen} onClose={() => this.setState({finishedModalOpen: false})}>
            <DialogTitle>Account created successfully!<IconButton style={{float: 'right', marginTop: "-10px"}} aria-label="close" onClick={() => this.setState({finishedModalOpen: false})}><CloseIcon /></IconButton></DialogTitle>
            <DialogContent>
              <DialogContentText>
              To access the DLM e-book, please log in with your newly created Username and Password by clicking LOG IN in the upper-right hand corner.
              </DialogContentText>
            </DialogContent>

          </Dialog>

        <Snackbar  
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }} 
          open={this.state.confirmationSnackBar} 
          onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({confirmationSnackBar: false})}
          }
          autoHideDuration={5000} 
          message="Code resent successfully!" />

        <Snackbar 
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.errorSnackBar}
          onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({errorSnackBar: false})}
          }
          autoHideDuration={5000}
          message={this.state.error.message} />
        </div>
      );
    }
  }

export default App;
