/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:87d2cb03-d872-4934-9e50-02997b7ec746",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_R300afotG",
    "aws_user_pools_web_client_id": "829kjtjcvu0tsi91v4mletv5o",
    "oauth": {},
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_mobile_analytics_app_id": "58202673ba4c4effa06baab5225d2be8",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
