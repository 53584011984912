import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { useSwipeable, Swipeable } from 'react-swipeable';
import { withStyles } from "@material-ui/core/styles";

import {ExpandLess, ExpandMore, ZoomIn, ZoomOut} from '@material-ui/icons'
import MenuIcon from '@material-ui/icons/Menu';

import Sidebar from "./Sidebar";
import {fakePages, offset, inverseOffset} from './helpers'
import  grey from '@material-ui/core/colors/grey'

import cookies from 'react-cookies'

const styles = {
  root: {
    background: "black"
  },
  input: {
    color: "white"
  }
};

class NavBar extends Component {
  constructor(props) {
    super(props);

    let tableOfContents = [
      {
        "name": "TITLE PAGE",
        "section": 0,
        "page": 1,
        "subsections": []
      },
      {
        "name": "TABLE OF CONTENTS",
        "section": 0,
        "page": 2,
        "subsections": []
      },
      {
        "name": "FOREWORD",
        "section": 0,
        "page": 5,
        "subsections": []
      },
      {
        "name": "INTRODUCTION",
        "section": 1,
        "page": 6,
        "subsections": [
          {
            "name": "THE CONDUCTOR AS ARTISTIC LEADER AND PEDAGOGUE",
            "section": 1,
            "page": 6,
            "subsections": []
          },
          {
            "name": "THE COMBINATION OF MUSICIANSHIP AND TECHNICAL PRECISION",
            "section": 2,
            "page": 6,
            "subsections": []
          },
          {
            "name": "A COMMON LANGUAGE FOR REHEARSAL",
            "section": 3,
            "page": 7,
            "subsections": []
          },
          {
            "name": "THE DIRECTED LISTENING MODEL",
            "section": 4,
            "page": 7,
            "subsections": []
          },
          {
            "name": "THE LISTENING PROCESS",
            "section": 5,
            "page": 10,
            "subsections": [
              {
                "name": "Listening Awareness Levels",
                "section": "5a",
                "page": 10,
              },
              {
                "name": "Directed Listening",
                "section": "5b",
                "page": 13,
              }
            ]
          },
          {
            "name": "APPLICATION OF THE DIRECTED LISTENING MODEL",
            "section": 6,
            "page": 14,
            "subsections": [
              {
                "name": "Ensemble Skills Development",
                "section": "6a",
                "page": 14,
              },
              {
                "name": "The Rehearsal Process",
                "section": "6b",
                "page": 15,
              }
            ]
          },
        ]
      },
      {
        "name": "PULSE",
        "section": 2,
        "page": 17,
        "subsections": [
          {
            "name": "TERMINOLOGY",
            "section": 0,
            "page": 16,
            "subsections": []
          },
          {
            "name": "EVEN FLOW OF TIME",
            "section": 1,
            "page": 17,
            "subsections": []
          },
          {
            "name": "PULSE, BEAT-MOTION,AND SUBDIVISION",
            "section": 2,
            "page": 18,
            "subsections": [
              {
                "name": "System for Conceptualizing Subdivision",
                "section": "2a",
                "page": 19,
              },
              {
                "name": "Internalization of the Subdivided Beat-Motion",
                "section": "2b",
                "page": 19,
              }
            ]
          },
          {
            "name": "RHYTHM",
            "section": 3,
            "page": 20,
            "subsections": [
              {
                "name": "Duration of Sound and Silence",
                "section": "3a",
                "page": 20,
              },
              {
                "name": "Conceptualizing Rhythms",
                "section": "3b",
                "page": 22,
              }
            ]
          },
          {
            "name": "DIRECTED LISTENING AND AURAL ANALYSIS",
            "section": 4,
            "page": 24,
            "subsections": []
          },
          {
            "name": "ENSEMBLE SKILLS DEVELOPMENT (EXERCISES)",
            "section": 5,
            "page": 25,
            "subsections": [
              {
                "name": "Conceptualizing Sonic Space",
                "section": 5.1,
                "page": 25,
              },
              {
                "name": "Vocalizing Subdivisions",
                "section": 5.2,
                "page": 26,
              },
              {
                "name": "Vocalizing Subdivisions with Body Movement",
                "section": 5.3,
                "page": 28,
              },
              {
                "name": "Counting Rhythms",
                "section": 5.4,
                "page": 31,
              }
            ]
          },
        ]
      },
      {
        "name": "SOUND PRODUCTION",
        "section": 3,
        "page": 41,
        "subsections": [
          {
            "name": "TERMINOLOGY",
            "section": 0,
            "page": 40,
            "subsections": []
          },
          {
            "name": "POSTURE",
            "section": 1,
            "page": 42,
            "subsections": []
          },
          {
            "name": "EMBOUCHURE",
            "section": 2,
            "page": 42,
            "subsections": []
          },
          {
            "name": "AIRSTREAM",
            "section": 3,
            "page": 58,
            "subsections": [
              {
                "name": "Metered Inhalation and Exhalation",
                "section": "3a",
                "page": 58,
                "subsections": []
              }
            ]
          },
          {
            "name": "VOWEL SHAPE",
            "section": 4,
            "page": 59,
            "subsections": []
          },
          {
            "name": "CHARACTERISTIC SOUND QUALITY",
            "section": 5,
            "page": 60,
            "subsections": [
              {
                "name": "Resonance",
                "section": "5a",
                "page": 62,
                "subsections": []
              },
              {
                "name": "Intonation",
                "section": "5b",
                "page": 62,
                "subsections": []
              }
            ]
          },
          {
            "name": "NOTE SHAPE",
            "section": 6,
            "page": 65,
            "subsections": [
              {
                "name": "Note Start",
                "section": "6a",
                "page": 66,
                "subsections": []
              },
              {
                "name": "Note Body (Body of Sound)",
                "section": "6b",
                "page": 67,
                "subsections": []
              },
              {
                "name": "Release",
                "section": "6c",
                "page": 67,
                "subsections": []
              }
            ]
          },
          {
            "name": "MOTOR SKILLS",
            "section": 7,
            "page": 68,
            "subsections": [
              {
                "name": "Assessing Motor Skills for Wind Instruments",
                "section": "7a",
                "page": 68,
                "subsections": []
              },
              {
                "name": "Assessing Motor Skills for Drums and Keyboard Instruments",
                "section": "7b",
                "page": 70,
                "subsections": []
              },
              {
                "name": "Assessing Motor Skills for Cymbals and Auxiliary Percussion",
                "section": "7c",
                "page": 74,
                "subsections": []
              }
            ]
          },
          {
            "name": "INSTRUMENT TENDENCIES",
            "section": 8,
            "page": 77,
            "subsections": [
              {
                "name": "Tendencies for Brass Instruments",
                "section": "8a",
                "page": 77,
                "subsections": []
              },
              {
                "name": "Alternate Fingerings and Playing Techniques for Woodwind Instruments",
                "section": "8b",
                "page": 81,
                "subsections": []
              },
              {
                "name": "Other Factors that Influence Pitch",
                "section": "8c",
                "page": 83,
                "subsections": []
              },
            ]
          },
          {
            "name": "TROUBLESHOOTING SOUND PRODUCTION ISSUES",
            "section": 9,
            "page": 84,
            "subsections": [
              {
                "name": "Troubleshooting Sound Production Issues for Wind Instruments",
                "section": "9a",
                "page": 84,
                "subsections": []
              },
              {
                "name": "Troubleshooting Sound Production Issues for Percussion Instruments",
                "section": "9b",
                "page": 85,
                "subsections": []
              }
            ]
          },
          {
            "name": "DIRECTED LISTENING AND AURAL ANALYSIS",
            "section": 10,
            "page": 89,
            "subsections": []
          },
          {
            "name": "ENSEMBLE SKILLS DEVELOPMENT EXERCISES",
            "section": 11,
            "page": 91,
            "subsections": [
              {
                "name": "Breathing Exercises and Relating Airstream to Sonic Space",
                "section": "11.1",
                "page": 91,
                "subsections": []
              },
              {
                "name": "Long Tone Exercise",
                "section": "11.2",
                "page": 93,
                "subsections": []
              },
              {
                "name": "Default Note Shape Exercise",
                "section": "11.3",
                "page": 95,
                "subsections": []
              },
              {
                "name": "Interval Exercises (Descending and Ascending)",
                "section": "11.4",
                "page": 99,
                "subsections": []
              },
              {
                "name": "Scale Exercises",
                "section": "11.5",
                "page": 102,
                "subsections": []
              },
              {
                "name": "Check Patterns for Percussion Played with Sticks or Mallets",
                "section": "11.6",
                "page": 103,
                "subsections": []
              },
              {
                "name": "Bass Drum, Cymbal, Triangle, and Tambourine technique",
                "section": "11.7",
                "page": 106,
                "subsections": []
              }
            ]
          }
        ]
      },
      {
        "name": "COMPREHENSIVE BALANCE",
        "section": 4,
        "page": 109,
        "subsections": [
          {
            "name": "TERMINOLOGY",
            "section": 0,
            "page": 108,
            "subsections": []
          },
          {
            "name": "VOLUME",
            "section": 1,
            "page": 110,
            "subsections": []
          },
          {
            "name": "SOUND COLOR",
            "section": 2,
            "page": 110,
            "subsections": []
          },
          {
            "name": "TONAL ENERGY",
            "section": 3,
            "page": 111,
            "subsections": [
              {
                "name": "Individual Tonal Energy",
                "section": "3a",
                "page": 111,
                "subsections": []
              },
              {
                "name": "Section Tonal Energy",
                "section": "3b",
                "page": 112,
                "subsections": []
              },
              {
                "name": "Ensemble Tonal Energy",
                "section": "3c",
                "page": 113,
                "subsections": []
              }
            ]
          },
          {
            "name": "ENSEMBLE SYMMETRY",
            "section": 4,
            "page": 114,
            "subsections": []
          },
          {
            "name": "COMPREHENSIVE BALANCE CHART",
            "section": 5,
            "page": 114,
            "subsections": []
          },
          {
            "name": "COMPREHENSIVE BALANCE EQUATION",
            "section": 6,
            "page": 117,
            "subsections": []
          },
          {
            "name": "CONTEXTUAL APPLICATION IN MUSIC",
            "section": 7,
            "page": 120,
            "subsections": [
              {
                "name": "Musical Functions of Sound and Role of Sound Color",
                "section": "7a",
                "page": 120,
                "subsections": []
              },
              {
                "name": "Combination of Sound Color",
                "section": "7b",
                "page": 122,
                "subsections": []
              }
            ]
          },
          {
            "name": "DIRECTED LISTENING AND AURAL ANALYSIS",
            "section": 8,
            "page": 124,
            "subsections": []
          },
          {
            "name": "ENSEMBLE SKILLS DEVELOPMENT EXERCISES",
            "section": 9,
            "page": 125,
            "subsections": [
              {
                "name": "Passing a Note Around the Ensemble",
                "section": "9.1",
                "page": 125,
                "subsections": []
              },
              {
                "name": "Establishing Comprehensive Balance and Ensemble Symmetry with a 4-Part Chorale",
                "section": "9.2",
                "page": 128,
                "subsections": []
              },
              {
                "name": "Balancing Ensemble Color within a 4-Part Chorale",
                "section": "9.3",
                "page": 130,
                "subsections": []
              }
            ]
          }
        ]
      },
      {
        "name": "MUSICALITY",
        "section": 5,
        "page": 135,
        "subsections": [
          {
            "name": "TERMINOLOGY",
            "section": 0,
            "page": 134,
            "subsections": []
          },
          {
            "name": "ARTICULATION",
            "section": 1,
            "page": 136,
            "subsections": [
              {
                "name": "Altering the Default Note Shape",
                "section": "1a",
                "page": 136,
                "subsections": []
              },
              {
                "name": "Articulation Markings",
                "section": "1b",
                "page": 138,
                "subsections": []
              },
              {
                "name": "Interpretation of Articulation Markings",
                "section": "1c",
                "page": 139,
                "subsections": []
              }
            ]
          },
          {
            "name": "DYNAMICS",
            "section": 2,
            "page": 140,
            "subsections": [
              {
                "name": "Prolonged Dynamic Alterations",
                "section": "2a",
                "page": 141,
                "subsections": []
              },
              {
                "name": "Momentary Dynamic Alterations",
                "section": "2b",
                "page": 142,
                "subsections": []
              }
            ]
          },
          {
            "name": "PHRASING",
            "section": 3,
            "page": 143,
            "subsections": [
              {
                "name": "Forward Motion and Musical Emphasis",
                "section": "3a",
                "page": 143,
                "subsections": []
              },
              {
                "name": "Harmonic Motion and Cadential Clarity",
                "section": "3b",
                "page": 146,
                "subsections": []
              }
            ]
          },
          {
            "name": "DIRECTED LISTENING AND AURAL ANALYSIS",
            "section": 4,
            "page": 148,
            "subsections": []
          },
          {
            "name": "ENSEMBLE SKILLS DEVELOPMENT EXERCISES",
            "section": 5,
            "page": 149,
            "subsections": [
              {
                "name": "Articulation Exercise",
                "section": "5.1",
                "page": 149,
                "subsections": []
              },
              {
                "name": "Forward Motion Exercise",
                "section": "5.2",
                "page": 152,
                "subsections": []
              },
              {
                "name": "Musical Emphasis Exercise",
                "section": "5.3",
                "page": 154,
                "subsections": []
              },
              {
                "name": "Chord Progressions",
                "section": "5.4",
                "page": 156,
                "subsections": []
              },
              {
                "name": "Chorales",
                "section": "5.5",
                "page": 158,
                "subsections": []
              }
            ]
          }
        ]
      },
      {
        "name": "THE REHEARSAL PROCESS",
        "section": 6,
        "page": 161,
        "subsections": [
          {
            "name": "TERMINOLOGY",
            "section": 0,
            "page": 160,
            "subsections": []
          },
          {
            "name": "REHEARSING WITH LESS ADVANCED ENSEMBLES",
            "section": 1,
            "page": 162,
            "subsections": []
          },
          {
            "name": "REHEARSING WITH A MORE ADVANCED ENSEMBLE",
            "section": 2,
            "page": 166,
            "subsections": []
          },
          {
            "name": "REHEARSING WITH A PROFESSIONAL ASPIRATION",
            "section": 3,
            "page": 167,
            "subsections": []
          },
          {
            "name": "REHEARSAL TECHNIQUES",
            "section": 4,
            "page": 168,
            "subsections": [
              {
                "name": "Wind Patterns",
                "section": "4.1",
                "page": 168,
                "subsections": []
              },
              {
                "name": "Positioning Notes with Fundamental Articulation Syllables",
                "section": "4.2",
                "page": 169,
                "subsections": []
              },
              {
                "name": "Playing Rhythms on One Note",
                "section": "4.3",
                "page": 170,
                "subsections": []
              },
              {
                "name": "Subdividing",
                "section": "4.4",
                "page": 170,
                "subsections": []
              },
              {
                "name": "Bopping",
                "section": "4.5",
                "page": 171,
                "subsections": []
              },
              {
                "name": "Modeling",
                "section": "4.6",
                "page": 171,
                "subsections": []
              }
            ]
          }
        ]
      },
      {
        "name": "CONCLUSION",
        "section": 0,
        "page": 173,
        "subsections": []
      }
    ]

    this.state = {
      drawerOpen: false,
      tableDrawers: [],
      tableOfContents: tableOfContents,
      navbarhidden: false,
      windowScale: 1.2
    };
    // adding in the state variables
    for (let category in tableOfContents) {
      let tableDrawers = this.state.tableDrawers;
      tableDrawers[category.name + category.section] = false;
      this.setState({tableDrawers});

      for (let section in category.subsections) {
        let tableDrawers = this.state.tableDrawers;
        tableDrawers[section.name + section.section] = false;
        this.setState({tableDrawers});
      }
    }
  }

  toggleDrawer(key) {
    let tableDrawers = this.state.tableDrawers;
    tableDrawers[key] = !tableDrawers[key]
    this.setState({tableDrawers})
  }
  
  sideList() {

    return (
      <div
        role="presentation"
        onClick={(e) => {
          // If we're expanding/collapsing the menu we don't want to close it
          if (e.target.nodeName === "path" || e.target.nodeName === "svg") {
            return
          }
          this.setState({drawerOpen: false})
        }}
        style={{float: "left"}}
      >
        {this.state.tableOfContents.map(category =>
          <List>
            {category.subsections.length > 0 ? 
              <ListItem>
                <ListItemText primary={category.section === 0 ? category.name : category.section + ". " + category.name} button onClick={() => this.changePage(category.page)}/>
                <ListItemIcon button onClick={() => this.toggleDrawer(category.name + category.section)}>
                  {this.state.tableDrawers[category.name + category.section] ? <ExpandLess /> : <ExpandMore />}
                </ListItemIcon>
              </ListItem>
              :
              <ListItem button onClick={() => this.changePage(category.page)}>
                <ListItemText primary={category.section === 0 ? category.name : category.section + ". " + category.name}/>
              </ListItem>
            }

            <Collapse in={this.state.tableDrawers[category.name + category.section]} timeout="auto" unmountOnExit>
              {category.subsections.map(section =>
                <List>
                  {section.subsections.length > 0 ? 
                    <ListItem>
                      <ListItemText inset={true} primary={section.section === 0 ? section.name : section.section + ". " + section.name} button onClick={() => this.changePage(section.page)}/>
                      <ListItemIcon button onClick={() => this.toggleDrawer(section.name + section.section)}>
                        {this.state.tableDrawers[section.name + section.section] ? <ExpandLess /> : <ExpandMore />}
                      </ListItemIcon>
                    </ListItem>
                    :
                    <ListItem button onClick={() => this.changePage(section.page)}>
                      <ListItemText inset={true} primary={section.section === 0 ? section.name : section.section + ". " + section.name}/>
                    </ListItem>
                  }


                  <Collapse in={this.state.tableDrawers[section.name + section.section]} timeout="auto" unmountOnExit>
                    {section.subsections.map(subsection => 
                      <ListItem button onClick={() => this.changePage(subsection.page)}>
                        <ListItemText inset={true} secondary={subsection.section + ". " + subsection.name} />
                      </ListItem>
                    )}
                  </Collapse>
                </List>
              )}
            </Collapse>
          </List>
        )}
      </div>
    )
  }

  changePage(page) {
    console.log(page)
    window.PdfViewer.viewer.currentPageNumber = inverseOffset(page);
  }

  openNavBar() {
    this.setState({navbarhidden: false})
  }

  closeNavBar() {
    this.setState({navbarhidden: true})
  }

  renderPageNumber() {
    // because we don't know how to do scroll listeners :/
    return ""
    const {pageNumber, numPages} = this.props;
    const numberPagesView = this.props.numberPagesView();

    if (pageNumber < 3) { return "Cover" }

    // if the only thing that's on the screen is one blank page
    if (fakePages.includes(pageNumber) && numberPagesView === 1) { return "Blank Page" }
    
    // if the second page is a fake, or we only have one page on the screen
    //if ((fakePages.includes(pageNumber + 1) && numberPagesView === 2) || numberPagesView === 1) {
    return "Page " + offset(pageNumber)
    // }

    // if (numberPagesView === 2) {
    //   return "Page " + offset(pageNumber) + " - " + (offset(pageNumber) + 1) + " of " + numPages
    // }
    
    // return "error"
  }

  zoomIn() {
    const factor = 0.1
    const newScale = Math.min(5, this.state.windowScale + factor)
    console.log(newScale)
    window.PdfViewer.viewer.currentScale = newScale
    this.setState({ windowScale: newScale})
  }

  zoomOut() {
    const factor = 0.1
    const newScale = Math.max(0.5, this.state.windowScale - factor)
    window.PdfViewer.viewer.currentScale = newScale
    this.setState({ windowScale: newScale})
  }
  
  removeAllNotes() {
    this.props.resetHighlights()
    cookies.remove("highlights")
  }

  render() {
    return (
      <div>
        <Swipeable onSwipedDown={() => this.openNavBar()} onSwipedUp={() => this.closeNavBar()}>
        <AppBar position="relative" style={{ background: "black", fontFamily: 'Lato'}}>
        
          <Toolbar
          style={{display: "flex", justifyContent: "space-between"}}
          >
            {this.props.loggedIn ? 
              <IconButton style={{padding:"10px", float:"right"}} edge="start" color="inherit" aria-label="menu" onClick={() => this.setState({drawerOpen: true})}>
                <MenuIcon />
              </IconButton>
            : null}

            {this.props.loggedIn ?
            null :
            <Typography variant="h6" style={{fontFamily: 'Lato'}}>
              THE DIRECTED LISTENING MODEL - EBOOK READER
            </Typography>
             }
             
             
             {this.props.loggedIn ? 
              <Typography variant="h6" style={{fontFamily: 'Lato'}}>
              THE DIRECTED LISTENING MODEL
            </Typography>
              :
              null }
             
            
            {this.props.loggedIn ? 
            <p style={{padding: "5px"
          }}
            >{this.renderPageNumber()}</p>
              : null}

              {this.props.loggedIn ? 
              <div>
                <Button 
                  variant="text"
                  color="white" 
                  onClick={() => this.props.pageDown(this.props.pageNumber)}
                  style={{padding: "2px",
                  color: "white",
                  position:"right",
                  fontFamily: 'Lato',
                  textTransform: "none"
                }}
                  >
                    Previous Page
                </Button> 
                <p style={{display: "inline"}}> | </p> 
                <Button
                  variant="text"
                  color="white" 
                  onClick={() => this.props.pageUp(this.props.pageNumber, this.props.numPages)}
                  style={{padding: "2px",
                  color: "white",
                  fontFamily: 'Lato',
                  textTransform: "none"
                }}
                  >
                    Next Page
                </Button>
                <p style={{display: "inline"}}> | </p> 
                <Button
                  variant="text"
                  color="white" 
                  onClick={() => this.props.openPageNumberModal()}
                  style={{padding: "2px",
                  color: "white",
                  fontFamily: "Lato",
                  textTransform: "none"
                  }}
                  >
                      Go To Page
                </Button>
              </div>
            
              : null}

            {this.props.loggedIn ? 
              <div>
                <IconButton style={{padding:"10px", float:"right"}} edge="start" color="inherit" aria-label="menu" onClick={() => this.zoomIn()}>
                  <ZoomIn />
                </IconButton>
                <IconButton style={{padding:"10px", float:"right"}} edge="start" color="inherit" aria-label="menu" onClick={() => this.zoomOut()}>
                  <ZoomOut />
                </IconButton>
              </div>
              :
              null }
              

{/*
            <Typography style={{ marginLeft: 'auto' }}>
              Hi {this.props.username}!
            </Typography>
*/}
          {/*
            this.props.loggedIn ? null : 
            <Button color="inherit" onClick={this.props.openAccessCodeModal} style={{float:"right", margin:"auto",padding:"5px", fontFamily: 'Lato', position:"right",textTransform: "none"}} >
              Redeem Access Code
            </Button>
          */}
            
            <Button color="inherit" onClick={this.props.onClick} style={{float:"right", margin:"left",padding:"5px",fontFamily: 'Lato',textTransform: "none"}} >
            {this.props.loggedIn ? "Log Out" : "Log In"}
            </Button>

          </Toolbar>
         
        </AppBar>
        </Swipeable>
        <Drawer open={this.state.drawerOpen} onClose={() => this.setState({drawerOpen: false})}>
            <div style={{display: "inline"}}>
                {this.sideList()}
                {/* this the TABLE OF CONTENTS this.sideList()*/}
                <Sidebar
                    highlights={this.props.highlights}
                    deleteHighlight={this.props.deleteHighlight}
                    resetHighlights={() => this.removeAllNotes()}
                    />
            </div>
        </Drawer>
      </div>
    );
  }
}

export default withStyles(styles)(NavBar);