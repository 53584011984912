// @flow

import React from "react";

import {fakePages, offset, inverseOffset} from './helpers'

import type { T_Highlight } from "react-pdf-highlighter/src/types";
type T_ManuscriptHighlight = T_Highlight;

type Props = {
  highlights: Array<T_ManuscriptHighlight>,
  resetHighlights: () => void
};

const updateHash = highlight => {
  document.location.hash = `highlight-${highlight.id}`;
};

function Sidebar({ highlights, deleteHighlight, resetHighlights }: Props) {
  return (
    <div className="sidebar" style={{ width: "40vw" }}>
      <div className="description" style={{ padding: "1rem" }}>
        <h2 style={{ marginBottom: "1rem" }}>Notes</h2>

        <p>
          <small>
            To add a note or highlight to the text, drag the mouse over the PDF and select Add Highlight.
          </small>
        </p>

{/*
        <p>
          <small>
            To highlight a larger area, hold ⌥ Option key (Alt), then click and drag. Added notes may be viewed by hovering over the highlighted area.
          </small>
        </p>
*/}

        <p>
          <small>
            All added notes are shown below, and by clicking a note below you will be redirected to that highlighted area in the text.
          </small>
        </p>

      </div>

      <ul className="sidebar__highlights">
        {highlights.map((highlight, index) => (
          <li
            key={index}
            className="sidebar__highlight"
            onClick={() => {
              updateHash(highlight);
            }}
          >
            <div>
              <strong>{highlight.comment.text}</strong>
              {highlight.content.text ? (
                <blockquote style={{ marginTop: "0.5rem" }}>
                  {`${highlight.content.text.slice(0, 90).trim()}…`}
                </blockquote>
              ) : null}
              {highlight.content.image ? (
                <div
                  className="highlight__image"
                  style={{ marginTop: "0.5rem" }}
                >
                  <img src={highlight.content.image} alt={"Screenshot"} />
                </div>
              ) : null}
            </div>
            <div className="highlight__location">
              Page {offset(highlight.position.pageNumber)}
            </div>
            <button onClick={() => deleteHighlight(index)}>Remove note</button>
          </li>
        ))}
      </ul>
      {highlights.length > 0 ? (
        <div style={{ padding: "1rem" }}>
          <button onClick={resetHighlights}>Remove all notes</button>
        </div>
      ) : null}
    </div>
  );
}

export default Sidebar;
