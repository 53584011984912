

import React, { Component } from "react";
import Amplify, { Auth } from 'aws-amplify';
import { withAuthenticator, SignIn, RequireNewPassword, ForgotPassword } from 'aws-amplify-react'
import awsconfig from './aws-exports'
import NavBar from './NavBar';
import awsmobile from './aws-exports';
import URLSearchParams from "url-search-params";

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import { useSwipeable, Swipeable } from 'react-swipeable'
import {fakePages, offset, inverseOffset} from './helpers'

import PdfLoader from "./react-pdf-highlighter/src/components/PdfLoader"
import PdfHighlighter from "./react-pdf-highlighter/src/components/PdfHighlighter"
import Tip from "./react-pdf-highlighter/src/components/Tip"
import Highlight from "./react-pdf-highlighter/src/components/Highlight"
import Popup from "./react-pdf-highlighter/src/components/Popup"
import AreaHighlight from "./react-pdf-highlighter/src/components/AreaHighlight"

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import cookie from 'react-cookies'

// import {
//   // PdfLoader,
//   // PdfHighlighter,
//   Tip,
//   Highlight,
//   Popup,
//   AreaHighlight
// } from "react-pdf-highlighter";

import testHighlights from "./test-highlights";

import Spinner from "./Spinner";


import type {
  T_Highlight,
  T_NewHighlight
} from "react-pdf-highlighter/src/types";

import "./style/App.css";

type T_ManuscriptHighlight = T_Highlight;

type Props = {};

type State = {
  highlights: Array<T_ManuscriptHighlight>
};

const getNextId = () => String(Math.random()).slice(2);

const parseIdFromHash = () =>
  document.location.hash.slice("#highlight-".length);

const resetHash = () => {
  document.location.hash = "";
};

const HighlightPopup = ({ comment }) =>
  comment.text ? (
    <div className="Highlight__popup">
      {comment.emoji} {comment.text}
    </div>
  ) : null;

const DEFAULT_URL = "static/1708.08021.pdf";

const searchParams = new URLSearchParams(document.location.search);
const url = DEFAULT_URL;

Amplify.configure(awsconfig);
class BookView extends Component<Props, State> {
  constructor () {
    super()
    this.state = {
        numPages: 176,
        pageNumber: 1,
        WannaGoBack: false,
        pagesDoubled: false, // static, if the pdf includes two pages per
        scaledup: false,
        scaledup2: false,
        lastPage: 174,
        windowSize: window.innerWidth,
        rescaleWidth: 1000,
        scale: 1,
        scale2: 1,
        navbaropen: false,

        highlightedText: "",
        anchorEl: null,
        popperOpen: false,
        highlights: [],

        pageNumberModalOpen: false,
        pageNumberModal: "1"
    }

    this.changePage = this.changePage.bind(this)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.onePageView = this.onePageView.bind(this)
    this.pageUp = this.pageUp.bind(this)
    this.pageDown = this.pageDown.bind(this)

    this.handleScroll = this.handleScroll.bind(this);

    this.openPageNumberModal = this.openPageNumberModal.bind(this)
}
componentDidMount() {
  window.addEventListener('resize', this.updateWindowDimensions);
  this.setState({
    windowSize: window.innerWidth,
    numPages: window.PdfViewer.viewer.pagesCount
  })

  window.addEventListener('scroll', this.handleScroll);

  window.PdfViewer.scrollMode = 1;
}

componentWillUnmount() {
  window.removeEventListener('resize', this.updateWindowDimensions);
}

handleScroll() {
  console.log("scrollin")
  if (window.PdfViewer.viewer.currentPageNumber !== this.state.pageNumber) {
    this.setState({pageNumber: window.PdfViewer.viewer.currentPageNumber})
  }
}

updateWindowDimensions() {
  console.log("RESIZE")
  const prevOnePageView = this.onePageView()
  this.setState({ windowSize: window.innerWidth }, () => {
    if (prevOnePageView && !this.onePageView()) {
      this.setState({
        pageNumber: this.state.pageNumber - (this.state.pageNumber + 1 ) % 2
      })
    }
  });
}

changeRequestedPage = ( e ) => {
  this.setState({pageNumber: parseInt(e.target.value)})
}

gotoPageNumber() {
  const page = inverseOffset(parseInt(this.state.pageNumberModal))
  this.setState({
    pageNumber: page,
    pageNumberModalOpen: false
  })
  window.PdfViewer.viewer.currentPageNumber = page
}

openPageNumberModal() {
  this.setState({pageNumberModalOpen: true})
}

pageUp(pageNumber, numPages) {
  //window.PdfViewer.viewer.currentScale = 0.875
  const incr = this.numberPagesView()
  // const incr = 1;
  if (pageNumber + incr > numPages) {
    return
  }
  const newPage = window.PdfViewer.viewer.currentPageNumber + incr
  window.PdfViewer.viewer.currentPageNumber = newPage
  this.setState({pageNumber: newPage})
  window.PdfViewer.scrollMode = 1;
}

pageUpnotButton() {
  const incr = this.numberPagesView()
  // const incr = 1;
  if (this.state.pageNumber + incr > this.state.numPages) {
    return
  }
  window.PdfViewer.viewer.currentPageNumber += incr
  this.setState({pageNumber: this.state.pageNumber + incr})
}

pageDown(pageNumber) {
  const incr = this.numberPagesView()
  if (pageNumber - incr < 1) {
    return
  }
  window.PdfViewer.viewer.currentPageNumber -= incr
  this.setState({pageNumber: this.state.pageNumber - incr})
 }

 pageDownotButton() {
  const incr = this.numberPagesView()
  if (this.state.pageNumber - incr < 1) {
    return
  }
  window.PdfViewer.viewer.currentPageNumber -= incr
  this.setState({pageNumber: this.state.pageNumber - incr})
}

logOut() {
  Auth.signOut()
    .then(data => console.log(data))
    .catch(err => console.log(err));
  this.props.exitBookView()
}

lastPageView() {
  return (this.state.pageNumber == this.state.lastPage)
}

onePageView() {
  if (this.lastPageView()) {
    return true
  }
  return this.state.windowSize < this.state.rescaleWidth
}

numberPagesView() {
    // all this is for INITIALIZATION
    // that is, the zoom is not defined (more importantly, neither is window)
    if (window.innerWidth < 1131) {
      return 1;
    }
    if (window.innerWidth < 1697) {
      return 2;
    }
    return 3;
}

getPageRange() {
  // if the last page view is only one page
  if (this.onePageView()) {
    return this.state.pageNumber
  }
  if (this.state.pageNumber - this.state.pageNumber % 2 + 2 > this.state.numPages) {
    return (this.state.pageNumber - this.state.pageNumber % 2 + 1)
  }
  return (this.state.pageNumber - this.state.pageNumber % 2 + 1) + " - " + (this.state.pageNumber - this.state.pageNumber % 2 + 2)
}

getPageNumber() {
  if (this.state.pagesDoubled) {
    if (this.state.pageNumber === 1) {
      return 1
    }
    return (this.state.pageNumber - this.state.pageNumber % 2 + 2) / 2
  }
  return this.state.pageNumber
}

changePage(pageNumber) {
  console.log("in")
  console.log(pageNumber)
  this.setState({pageNumber})
}

scaleup() {
  if(this.state.scaledup === false){    
  this.setState({scaledup:true})
  this.setState({scale:1.8})
  this.setState({scale2:0})
  }
  else if(this.state.scaledup === true){
    this.setState({scale:1})
    this.setState({scaledup:false})
    this.setState({scale2:1})
  }
}

scaleup2electricboogaloo() {
  if(this.state.scaledup2 == false){
  this.setState({scale2:1.8})
  this.setState({scale:0})
  this.setState({scaledup2:true})
  }
  else if(this.state.scaledup2 == true){
    this.setState({scale2:1})
    this.setState({scale:1})
    this.setState({scaledup2:false})
  }
}

  resetHighlights = () => {
    this.setState({
      highlights: []
    });
  };

  scrollViewerTo = (highlight: any) => {};

  scrollToHighlightFromHash = () => {
    const highlight = this.getHighlightById(parseIdFromHash());

    if (highlight) {
      this.scrollViewerTo(highlight);
    }
  };

  componentDidMount() {
    window.addEventListener(
      "hashchange",
      this.scrollToHighlightFromHash,
      false
    );

    let cookieHighlights = cookie.load("highlights");
    if (cookieHighlights === undefined) {
      cookieHighlights = []
    }

    this.setState({
      highlights: cookieHighlights
    })
  }

  getHighlightById(id: string) {
    const { highlights } = this.state;

    return highlights.find(highlight => highlight.id === id);
  }

  addHighlight(highlight: T_NewHighlight) {
    const { highlights } = this.state;

    console.log("Saving highlight", highlight);

    this.setState({
      highlights: [{ ...highlight, id: getNextId() }, ...highlights]
    }, () => {
      cookie.save("highlights", this.state.highlights)
    });
  }

  updateHighlight(highlightId: string, position: Object, content: Object) {
    console.log("Updating highlight", highlightId, position, content);

    this.setState({
      highlights: this.state.highlights.map(h => {
        return h.id === highlightId
          ? {
              ...h,
              position: { ...h.position, ...position },
              content: { ...h.content, ...content }
            }
          : h;
      })
    });
  }

  renderPage(pdfDocument, highlights, offset=0) {
    return (
      <PdfHighlighter
                pdfDocument={pdfDocument}
                enableAreaSelection={event => event.altKey}
                onScrollChange={resetHash}
                scrollRef={scrollTo => {
                  this.scrollViewerTo = scrollTo;

                  this.scrollToHighlightFromHash();
                }}
                offset={offset}
                onSelectionFinished={(
                  position,
                  content,
                  hideTipAndSelection,
                  transformSelection
                ) => (
                  <Tip
                    onOpen={transformSelection}
                    onConfirm={comment => {
                      this.addHighlight({ content, position, comment });

                      hideTipAndSelection();
                    }}
                  />
                )}
                highlightTransform={(
                  highlight,
                  index,
                  setTip,
                  hideTip,
                  viewportToScaled,
                  screenshot,
                  isScrolledTo
                ) => {
                  const isTextHighlight = !Boolean(
                    highlight.content && highlight.content.image
                  );

                  const component = isTextHighlight ? (
                    <Highlight
                      isScrolledTo={isScrolledTo}
                      position={highlight.position}
                      comment={highlight.comment}
                    />
                  ) : (
                    <AreaHighlight
                      highlight={highlight}
                      onChange={boundingRect => {
                        this.updateHighlight(
                          highlight.id,
                          { boundingRect: viewportToScaled(boundingRect) },
                          { image: screenshot(boundingRect) }
                        );
                      }}
                    />
                  );

                  return (
                    <Popup
                      popupContent={<HighlightPopup {...highlight} />}
                      onMouseOver={popupContent =>
                        setTip(highlight, highlight => popupContent)
                      }
                      onMouseOut={hideTip}
                      key={index}
                      children={component}
                    />
                  );
                }}
                highlights={highlights}
              />
    )
  }

  deleteHighlight = (pos) => {
    let highlights = this.state.highlights;
    highlights.splice(pos, 1)
    this.setState({ highlights })
  }

  render() {
    const { highlights } = this.state;
    //console.log(this.state.pageNumber)

    return (
      <div style={{position: "relative"}}>
      <NavBar 
        style={{zIndex: 5}}
        loggedIn={true}
        onClick={() => this.logOut()}
        pageUp={this.pageUp}
        pageDown={this.pageDown}
        pageNumber={this.state.pageNumber}
        numPages={this.state.numPages}
        highlights={highlights}
        deleteHighlight={this.deleteHighlight}
        resetHighlights={this.resetHighlights}
        numberPagesView={this.numberPagesView}
        openPageNumberModal={this.openPageNumberModal}
      />
      <div className="App" style={{ position: "absolute", top: 0, left: 0, display: "flex", height: "100vh"}}>
        <div
          style={{
            height: "100vh",
            width: "100vw",
            position: "relative"
          }}
        >
          <PdfLoader url={require("./TDLM.pdf")} beforeLoad={<Spinner />}>
            {pdfDocument => (
              <Swipeable onSwipedLeft={() => this.pageDown(this.state.pageNumber)} onSwipedRight={() => this.pageUp(this.state.pageNumber, this.state.numPages)}>
                  {this.renderPage(pdfDocument, highlights)}
              </Swipeable>
            )}
          </PdfLoader>
        </div>

        <Dialog open={this.state.pageNumberModalOpen} onClose={() => this.setState({pageNumberModalOpen: false})}>
            <DialogTitle>Please enter a page number <IconButton aria-label="close" onClick={() => this.setState({pageNumberModalOpen: false})}><CloseIcon /></IconButton></DialogTitle>
            <DialogContent>
              <TextField onChange={(e) => this.setState({pageNumberModal: e.target.value})} />
            </DialogContent>
            <DialogActions>
              <Button color="inherit" onClick={() => this.gotoPageNumber()}>
                Submit
              </Button>
            </DialogActions>
          </Dialog>
      </div>
      </div>
    );
  }
}

export default withAuthenticator(BookView, false,
  [
      <SignIn />,
      <RequireNewPassword />,
      <ForgotPassword />
  ]
);